import scrollTo from './scrollTo';

function getScrollTop() {
    return document.body.scrollTop || document.documentElement.scrollTop;
}

export default function scrollToElement(element, options = {}) {
    const box = element.getBoundingClientRect();
    const scrollTop = getScrollTop();
    const { offset = 20, loose = false, animate = true }  = options

    const target = scrollTop + box.top - offset

    const outOfViewport = box.top - offset < 0 || box.bottom > window.innerHeight

    if (!loose || (loose && outOfViewport)) {
        scrollTo(target, {
            animate: options.animate
        })
    }
}