import { scrollToElement } from '../../services/Scroll'

document.addEventListener('nette:valid', event => {
    if (event.defaultPrevented) {
        return
    }

    const element = event.target;
    const errorContainer = element.closest('.u-errorContainer') || element.parentNode

    if (element.tagName.toLowerCase() != 'button') {
        const errorElement = errorContainer.querySelector('.InputError');
        if (errorElement) {
            errorContainer.removeChild(errorElement)
        }
    }

    errorContainer.classList.remove('has-error');
    errorContainer.classList.add('is-valid');
});

document.addEventListener('nette:invalid', event => {
    if (event.defaultPrevented) {
        return
    }

    const element = event.target;
    const errorContainer = element.closest('.u-errorContainer') || element.parentNode

    let errorElement = errorContainer.querySelector('.InputError');

    if (!errorElement) {
        errorElement = document.createElement('div');
        errorElement.className = 'InputError';
    }

    errorElement.innerHTML = element.getAttribute('data-nette-error');
    errorContainer.appendChild(errorElement);
    errorContainer.classList.add('has-error');
    errorContainer.classList.remove('is-valid');
});

document.addEventListener('nette:form:valid', event => {});
document.addEventListener('nette:form:invalid', event => {
    const form = event.target
    const error = form.querySelector('.InputError')
    const target = error ? error.closest('.FormItem') : null
    scrollToElement(target ? target : form, -120);
});
