import Emitter from '../../core/Emitter'
import {
    getScrollTop as getScrollTopNative,
    getScroll as getScrollNative,
    enableScrolling as enableScrollingNative,
    disableScrolling as disableScrollingNative } from '../Viewport/scroll'
import throttle from 'lodash/throttle'
import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar'
import TouchMouseInputResolver from '../../meta/TouchMouseInputResolver'

import { default as scrollToElementNative } from '../Viewport/scrollToElement'
import { default as scrollToNative } from '../Viewport/scrollTo'

class PageWheelZoom extends ScrollbarPlugin {

    static pluginName = 'PageWheelZoom';

    constructor(...args) {
        super(...args)

        this.isCtrlPressed = false
    }

    onInit() {
        window.addEventListener('keydown', this.handleKeydown)
        window.addEventListener('keyup', this.handleKeyup)
    }

    transformDelta(delta, fromEvent) {

        if (this.isCtrlPressed) {
            return {
                x: 0,
                y: 0
            }
        }

        return delta
    }

    onDestroy() {
        window.removeEventListener('keydown', this.handleKeydown)
        window.removeEventListener('keyup', this.handleKeyup)
    }

    handleKeydown = event => {
        this.isCtrlPressed = event.ctrlKey
    }

    handleKeyup = event => {
        this.isCtrlPressed = event.ctrlKey
    }

}

Scrollbar.use(PageWheelZoom)

class Scroll extends Emitter {

    constructor(context = window) {
        super()

        this.context = context
        this.current = {
            x: 0,
            y: 0
        }
        this.raf = null
        this.scrollHeight = document.body.scrollHeight
        this.container = document.querySelector('.scroll-Container')

        this.handleResize = throttle(this.handleResize, 50)

        window.addEventListener('resize', this.handleResize)
        window.addEventListener('scroll', this.handleNativeScroll)

        this.scrollbar = null
        this.isVirtual = false

        this.staticLimit = {
            x: this.container.offsetWidth - window.innerWidth,
            y: this.container.offsetHeight - window.innerHeight
        }
    }

    getScroll() {
        if (this.isVirtual) {
            return {
                x: this.scrollbar.scrollLeft,
                y: this.scrollbar.scrollTop
            }
        } else {
            return getScrollNative()
        }
    }

    handleNativeScroll = () => {
        if (this.isVirtual) {
            return
        }

        this.current = getScrollNative()

        this.render()
    }

    handleResize = () => {
        this.resize()
    }

    handleVirtualScroll = status => {
        this.current = {
            x: status.offset.x,
            y: status.offset.y
        }

        this.render()
    }

    render = () => {
        this.emit('scroll', {
            offset: {...this.current}
        })
    }

    resize = () => {
        const scrollHeight = document.body.scrollHeight

        if (this.scrollHeight !== scrollHeight) {
            this.scrollHeight = scrollHeight
            this.emit('resize')
        }

        if (!this.isVirtual) {
            this.staticLimit = {
                x: this.container.offsetWidth - window.innerWidth,
                y: this.container.offsetHeight - window.innerHeight
            }
        }

        this.emit('resize')
    }

    update() {
        if (this.isVirtual) {
            this.scrollbar.update()
        }
    }

    setPosition(x, y) {
        if (this.isVirtual) {
            this.scrollbar.setPosition(x, y)
        } else {
            document.body.scrollLeft = x
            document.body.scrollTop = y
        }
    }

    useNative() {
        if (this.scrollbar) {
            Scrollbar.destroy(this.container)
            this.scrollbar = null
        }
        this.isVirtual = false
        this.container.classList.remove('is-virtual')
    }

    useVirtual() {
        this.isVirtual = true
        this.container.classList.add('is-virtual')

        if (!this.scrollbar) {
            this.scrollbar = Scrollbar.init(this.container, {
                renderByPixels: true
            })
            this.scrollbar.addListener(this.handleVirtualScroll)
        }
    }


    getLimit() {
        if (this.isVirtual && this.scrollbar) {
            return this.scrollbar.limit
        } else {
            return this.staticLimit
        }
    }
}

const scrollInstance = new Scroll()

const _defaults = {
    offset: 80,
    animate: true,
    duration: 600
}

export function scrollTo(y, options = {}) {
    options = {
        ..._defaults,
        ...options
    }

    const { duration, offset, animate, ...scrollOptions } = options

    if (scrollInstance.isVirtual) {
        if (animate) {
            scrollInstance.scrollbar.scrollTo(0, y, duration, scrollOptions)
        } else {
            scrollInstance.scrollbar.setPosition(0, y)
        }
    } else {
        scrollToNative(y)
    }
}

export function scrollToElement(element, options = {}) {
    options = {
        ..._defaults,
        ...options
    }

    if (scrollInstance.isVirtual) {
        if (options.animate) {
            scrollInstance.scrollbar.scrollIntoView(element, {
                offsetTop: options.offset ? options.offset : 0,
                onlyScrollIfNeeded: options.loose,
                alignToTop: true
            })
        } else {
            const box = element.getBoundingClientRect()
            scrollInstance.scrollbar.setPosition(0, box.top - options.offset)
        }
    } else {
        scrollToElementNative(element, options)
    }
}

export function getScroll() {
    if (scrollInstance.isVirtual) {
        return scrollInstance.scrollbar.offset
    } else {
        return getScrollNative()
    }
}

export function getScrollTop() {
    if (scrollInstance.isVirtual) {
        return scrollInstance.scrollbar.scrollTop
    } else {
        return getScrollTopNative()
    }
}

export function disableScrolling() {
    disableScrollingNative()
}

export function enableScrolling() {
    enableScrollingNative()
}

window.scrollInstance = scrollInstance

export default scrollInstance
